.hero {
	&--wrapper {
		position: relative;
		transform-style: preserve-3d;
	}
	&--full {
		height: 100vh;
		overflow: hidden;
	}
	&--bg-img {
		background-size: cover;
    	background-position: center center;
    	opacity: 0.5;
	}
	&--headline {
		position: absolute;
		top: 50%;
		right: $xxl;
		left: $xxl;
		transform: perspective(1px) translateY(-50%);
		text-align: center;
	}
}