// These styles are kind of themeable so feel free to change as required.
button {
    -webkit-appearance: none!important;
}

.btn {
    font-size: $sm;
    display: block;
    padding: $xsm $sm;
    margin-bottom: 0;
    line-height: 1.5;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border-radius: $xxs;
    border-width: 0.15rem;
    border-style: solid;
    @include transition(all 0.15s ease-in-out, box-shadow 0.15s ease-in-out);
    // @include colors((
    //     background-color: primary-color,
    //     color: contrasting-color,
    //     border-color: primary-color
    // ));
    &:focus {
        outline: none;
    }
    &:active {
        // box-shadow: inset 0 0.07rem 0.1rem 0.05rem rgba($low, 0.2);
    }
    &--constrained {
    	max-width: 15rem;
    }
    .site--header & {
    	font-size: $xsm;
    	// color: $low;
    	margin-top: $xxxs;
    	@media screen and (min-width: $screen-xsm) {
    		margin-top: $xs;
			font-size: $sm;
    	}
    }
}
