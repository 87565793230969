@mixin setBackgroundAndColor($background, $color) {
  background-color: $background!important;
  color: $color!important;
}

// This takes a sass map and maps it to the color-map, allowing you to create
// loads of colour styled elements

@mixin colors($attributes, $specificColors: "") {

  $isApplicable: true;
  // Iterate through the color-map data
  @each $label, $map in $color-map {
    @if ($specificColors != "") {
      $isApplicable: false;
      @each $color in $specificColors {
        // If the color has been picked as a specific color, do it
        @if $color == $label {
          $isApplicable: true;
        }
      }
    }

    @if ($isApplicable) {
      &--#{"" + $label} {
        // Map the attributes to the color
        @each $label, $attribute in $attributes {
          #{$label}: map_get($map, $attribute);
        }
      }
    }

  }
}