body {
  // @include grid-visual;
  // @include grid-container;
}

.site--header {
	padding: $xs $sm;
	position:  fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9001;
}

.main {
  margin: 0 auto;
}

.row {
  // @include grid-container;
}

.grid-col-3 {
  // @include grid-column(3);
}

.give-it-loads-of-space {
	padding: 6rem 0;
}

.l-flex {
    display: flex;
    align-items: center;
    &--at {
    	&--md {
    		@media screen and (min-width: $screen-md) {		
    			display: flex;
    			align-items: center;
    		}
    	}
    }
}

.legs-wrapper {
  @media screen and (max-width: $screen-xsm) {
    height: 480px;
    height: 66vw;
    position: relative;
    overflow: hidden;
    & img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}