
// Fonts

$font_main: 'europa','Helvetica Neue', Helvetica, Arial, sans-serif ;
$font_heading: 'europa','Helvetica Neue', Helvetica, Arial, sans-serif;

// We use $font_use-case for specificity
// We use appended naming to keep things clear and descriptive


// Colors 1

//$brand_primary: #18ad92; // Turquoise //
//$brand_secondary: #ef5b5b; // pink-red/Amaranth
//$white: #FFFFFF; // White //
//$low: #031f1f; // Dark Green
//$high: #f5f5f5; // Light Grey
// We try not to use specific colour names here (we can do that in the maps)

// Colors 2

$brand_primary: #057cb0; // Sky Blue RAL 5015 //
$brand_secondary: #ef372c; // Luminous Red RAL 3024 //
$white: #FFFFFF; // White //
$low: darken($brand_primary, 25%); // Dark Sky Blue
$high: #f5f5f5; // Light Grey



// Sizes

$xxxs: 0.125rem; //2px
$xxs: 0.25rem; // 4px
$xs: 0.5rem; // 8px
$xsm: 0.75rem; // 12px
$sm: 1rem; // 16px
$md: 1.5rem; // 24px
$lg: 2rem; // 32px
$xl: 2.5rem; // 40px
$xxl: 3rem; // 48px
$xxxl: 4rem; // 54px
// We work in factors of 8 where appropriate (less than 8 and we divide by 2)

// Screens
$screen-xsm: 569px!default;
$screen-xsm-min: $screen-xsm!default;

// Color map

$color-map: (
        blue: (
                primary-color: $brand_primary,
                contrasting-color: $white,
                secondary-color: $low
        ),
        red: (
                primary-color: $brand_secondary,
                contrasting-color: $white,
                secondary-color: $low
        ),
        white: (
                primary-color: $white,
                contrasting-color: $brand_primary,
                secondary-color: $brand_secondary
        ),
        low: (
                primary-color: $low,
                contrasting-color: $white,
                secondary-color: $brand_secondary
        ),
        high: (
                primary-color: $high,
                contrasting-color: $brand_primary,
                secondary-color: $brand_secondary
        )
);
// The colour map is project specific so we can amend the colour refs here
// We use these for such classes as bg--red or txt--green -- these will be
// Specific to the visual identity







// The label is the id, the map is the contents
@each $label, $map in $color-map {
    $primaryColor: map_get($map, primary-color);
    $contrastingColor: map_get($map, contrasting-color);
    $secondaryColor: map_get($map, secondary-color);

    .btn, .cell {
        &--#{$label} {
            @include setBackgroundAndColor($primaryColor, $contrastingColor);
            border-color: $primaryColor;
            box-shadow: 0 0 0 0 rgba($low, 0);
            //@include transition(all 0.1s ease);
            &:hover {
                background-color: tint($primaryColor, 15%)!important;
                border-color:  transparent!important;
                box-shadow: 0 1.5rem 2rem -1rem rgba($low, 0.5);
            }
            &--ghost {
                background-color: rgba($primaryColor, 0);
                color: $secondaryColor;
                border-color: $primaryColor;
                &:hover {
                    @include setBackgroundAndColor($secondaryColor,
                  $contrastingColor);
                    border-color: transparent!important;
                    color: $contrastingColor!important;
                    box-shadow: 0 1.5rem 2rem -1rem rgba($low, 0.5);
                }
            }
        }
        &--txt-low {
            color: $low;
            &:hover {
                color: inherit;
            }
        }
        &--txt-high {
            color: $white;
            &:hover {
                color: inherit;
            }
        }
        &--selected {
            // @include setBackgroundAndColor($tertiaryColor, $contrastingColor);
        }

    }
    .bg {
        &--#{$label} {
            @include setBackgroundAndColor($primaryColor, $contrastingColor);
            p {
                a {
                    //type 6 – box shadow contrast with opacity dip
                    position: relative;
                    overflow: hidden;
                    color: $primaryColor;
                    z-index: 2;
                    //@include transition(all 0.2s ease-in);
                    box-shadow: inset 0 -2em 0 0 $contrastingColor;
                    &:hover {
                        color: $primaryColor;
                        box-shadow: inset 0 -2em 0 0 rgba($contrastingColor, 0.85);
                    }
                }
            }
            & .pre-dash {
                &:before {
                    background-color: $contrastingColor;
                }
            }
            & > .dash-under {
                &:after, &:before {
                    background-color: $contrastingColor;
                }
            }
        }
    }
    .border {
        &--#{$label} {
            border-color: $primaryColor!important;
        }
    }
    .txt {
        &--#{$label} {
            color: $primaryColor!important;
            // &.pre-dash {
            //     &:before {
            //         background-color: $primaryColor;
            //     }
            // }
            // &.dash-under, & .dash-under {
            //     &:after, &:before {
            //         background-color: $dashColor;
            //     }
            // }
            & a {
                &:hover {
                    color: $secondaryColor;
                }
            }
        }
    }
    .stroke {
        &--#{$label} {
            stroke: $primaryColor;
        }
        &--none {
            stroke: none;
        }
    }
    .fill {
        &--#{$label} {
            fill: $primaryColor;
        }
        &--none {
            fill: none;
        }
    }
    .input {
        &--#{$label} {
            color: $primaryColor;
            &:focus {
                box-shadow: inset 0 0 0em 0.18em $primaryColor;
            }
        }
    }
    .dash {
        &--#{$label} {
            &:after, &:before {
                background-color: $primaryColor!important;
            }
        }
    }
    .table {
        &--#{$label} {
            background-color: $primaryColor;
            border-color: $secondaryColor;
            // color: $contrastingColor;
            & td {
                border-color: $secondaryColor;
                // color: $contrastingColor;
            }
        }
    }
}




// Gradients

//.cssgradients {
//    .bg-grad {
//        position: relative;
//        &:before {
//            content: '';
//            //@include position(absolute, 0 0 0 0);
//        }
//        &.grad-green {
//            &:before {
//                mix-blend-mode: difference;
//                @include linear-gradient(top right, rgba($brand_primary, 1), rgba($brand_primary, 1), $fallback: rgba($brand_primary, 0));
//                opacity: 1;
//            }
//        }
//        &.grad-gold {
//            &:before {
//                // mix-blend-mode: overlay;
//                // @include linear-gradient(top right, rgba($blue-high, 1), rgba($red, 1), $fallback: rgba($blue, 0));
//                // opacity: 1;
//            }
//            &--inv {
//                &:before {
//                    // mix-blend-mode: overlay;
//                    // @include linear-gradient(top right, rgba($red, 1), rgba($blue-high, 1), $fallback: rgba($blue, 0));
//                    // opacity: 1;
//                }
//            }
//        }
//        &.grad-grey {
//            &:before {
//                // mix-blend-mode: multiply;
//                // @include linear-gradient(bottom right, rgba($green-high, 1), rgba($blue, 1), $fallback: rgba($blue-high, 0));
//                // opacity: 1;
//            }
//            &--inv {
//                &:before {
//                    // mix-blend-mode: multiply;
//                    // @include linear-gradient(bottom right, rgba($blue, 1), rgba($green-high, 1), $fallback: rgba($green-high, 0));
//                    // opacity: 1;
//                }
//            }
//        }
//    }
//}
