//Some base styles for prototypes/archetypes that generally never change but can be changed per project – or overwrite them in _theme.scss

// * {
//     outline: solid red;
// }

body, html {
  margin: 0;
  padding: 0;
  font-size: 14px;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  @media screen and (min-width: $screen-sm) {
    font-size: 16px;
  }
  .nav--fixed {
    padding-top: 4em;
    // @media screen and (min-width: $screen-md) {
    //     padding-top: 4em;
    // }
  }
}

// without using position fixed we seem to have trouble giving the menu an overflow hidden property that will work on mobile.
.nav--is-open {
  overflow: hidden;
  @include position(absolute, 0 0 0 0);
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}

ul {
  &.unstyled {
    padding-left: 0;
    margin-left: 0;
    text-indent: 0;
    width: 100%;
    list-style: none;
    li {
      width: inherit;
      margin-left: 0;
      // list-style: none;
    }
  }
}

.lyr {
  &-style {
    &--multiply {
      mix-blend-mode: multiply;
    }
  }
}
