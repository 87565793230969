/////////////
/////////////
/*
We've all been there, in need of a helper class, all alone and scared. Afraid to ask. Well fear no more for helper classes have you found.
*/
////////////
////////////

.clearfix {
  &:before, &:after {
    display: table;
    width: 100%;
    clear: both;
    content: '';
  }
}

.hr {
  &--dashed {
    border-top: dashed 1px;
    position: relative;
    &:before {
      content: 'x';
      display: block;
      @include position(absolute, calc( 50% - 1rem / 2 ) calc( 50% - 6rem / 2 ) null null);
      width: 6rem;
      height: 1rem;
      line-height: 0.7rem;
      text-align: center;
    }
  }
  &--solid {
    border-top: solid 2px;
  }
  @include colors((
          border-top-color: primary-color
  ));
}

.blend {
  &--overlay {
    mix-blend-mode: overlay;
  }
  &--multiply {
    mix-blend-mode: multiply;
  }
  &--screen {
    mix-blend-mode: screen;
  }
  &--difference {
    mix-blend-mode: difference;
  }
  &--colorburn {
    mix-blend-mode: color-burn;
  }
}

//guess what this does
.pos {
  &-rel {
    position: relative;
  }
  &-fxd {
    position: fixed;
  }
  &-abs {
    position: absolute;
    &--va {
      &-mid {
        @extend .pos-abs;
        top: 50%;
        transform: translateY(-50%);
        &.rotate--xs {
          transform: translateY(-50%) rotate(-6deg);
        }
      }
      &-center {
        @include position(absolute, 50% null null 50%);
        transform: translateY(-50%), translateX(-50%);
        &.rotate--xs {
          transform: translateY(-50%), translateX(-50%), rotate(-6deg);
        }
      }
    }
  }
  &-top {
    &-0 {
      @extend .pos-abs;
      top: 0;
    }
    &-xs {
      @extend .pos-abs;
      top: 1rem;
    }
    &-sm {
      @extend .pos-abs;
      top: 1.5rem;
    }
    &-md {
      top: 2rem;
    }
    &-lg {
      @extend .pos-abs;
      top: 2.5rem;
    }
  }
  &-btm {
    &-0 {
      @extend .pos-abs;
      bottom: 0;
    }
    &-xs {
      @extend .pos-abs;
      bottom: 1rem;
    }
    &-sm {
      @extend .pos-abs;
      bottom: 1.5rem;
    }
    &-md {
      @extend .pos-abs;
      bottom: 2rem;
    }
    &-lg {
      @extend .pos-abs;
      bottom: 2.5rem;
    }
  }
  &-lft {
    &-0 {
      @extend .pos-abs;
      left: 0;
    }
    &-xs {
      @extend .pos-abs;
      left: 1rem;
    }
    &-sm {
      @extend .pos-abs;
      left: 1.5rem;
    }
    &-md {
      @extend .pos-abs;
      left: 2rem;
    }
    &-lg {
      @extend .pos-abs;
      left: 2.5rem;
    }
  }
  &-rgt {
    &-0 {
      @extend .pos-abs;
      right: 0;
    }
    &-xs {
      @extend .pos-abs;
      right: 1rem;
    }
    &-sm {
      @extend .pos-abs;
      right: 1.5rem;
    }
    &-md {
      @extend .pos-abs;
      right: 2rem;
    }
    &-lg {
      @extend .pos-abs;
      right: 2.5rem;
    }
  }
  &-all {
    &-0 {
      @include position( absolute, 0 );
    }
    &-xs {
      @include position( absolute, 1rem );
    }
    &-sm {
      @include position( absolute, 1.5rem );
    }
    &-md {
      @include position( absolute, 2rem );
    }
    &-lg {
      @include position( absolute, 2.5rem );
    }
  }
}

// center with margin – only works if element is is block
.mar-cent {
  margin: 0 auto;
  &--max--md {
    @media screen and (max-width: $screen-md) {
      margin: 0 auto;
    }
  }
}

.block {
  display: block;
}

// float yo'self
.flt-lft {
  float: left;
}

.flt-rgt {
  float: right;
  &--at--md {
  @media screen and (min-width: $screen-md) {
    float: right;
    }
  }
}

.txt-up {
  text-transform: uppercase;
}

.txt-low {
  text-transform: lowercase;
}

.txt-sntnc {
  text-transform: none;
}

// Quickly center some text
.txt-c {
  text-align: center;
}

.txt-r {
  text-align: right;
}

.txt-l {
  text-align: left;
}

.flex-mar {
  .flexbox & {
    margin: auto;
  }
}

.mar-0 {
  margin: 0 auto!important;
}

.pad-0 {
  padding: 0!important;
}

// Helper class for quickly making things fill their parent
.full-width {
  width: 100%;
}

// make it bold
.strong {
  font-weight: 700;
}

//make it bold af
.strongest {
  font-weight: 900;
}

//make it light
.lightweight {
  font-weight: 400;
}

//make it light af
.extralightweight {
  font-weight: 100;
}

// When an image is naturally less px wide than parent use this to force it fill the parent rather than stop at its natural px width
.img--full {
  width: 100%;
  max-width: 100%;
  height: auto;
}




//padding Spacing

//The padding does top and bottom for all sides use p-all-{var}
.p- {
  &-xxs {
    padding-top: $xxs;
    padding-bottom: $xxs;
  }
  &-xs {
    padding-top: $xs;
    padding-bottom: $xs;
  }
  &-sm {
    padding-top: $sm;
    padding-bottom: $sm;
  }
  &-md {
    padding-top: $md;
    padding-bottom: $md;
  }
  &-lg {
    padding-top: $lg;
    padding-bottom: $lg;
  }
  &-xl {
    padding-top: $xl;
    padding-bottom: $xl;
  }
  &-xxl {
    padding-top: $xxl;
    padding-bottom: $xxl;
  }
  &-xxxl {
    padding-top: $xxxl;
    padding-bottom: $xxxl;
  }
  &-all {
    &--xs {
      padding: $xs;
    }
    &--sm {
      padding: $sm;
    }
    &--md {
      padding: $md;
    }
    &--lg {
      padding: $lg;
    }
    &--xl {
      padding: $xl;
    }
  }
  &-top {
    &--xs {
      padding-top: $xs;
    }
    &--sm {
      padding-top: $sm;
    }
    &--md {
      padding-top: $md;
    }
    &--lg {
      padding-top: $lg;
    }
    &--xl {
      padding-top: $xl;
    }
  }
  &-btm {
    &--xs {
      padding-bottom: $xs;
    }
    &--sm {
      padding-bottom: $sm;
    }
    &--md {
      padding-bottom: $md;
    }
    &--lg {
      padding-bottom: $lg;
    }
    &--xl {
      padding-bottom: $xl;
    }
  }
}


// Margins
.m- {
  &-xxs {
    margin-top: $xxs;
    margin-bottom: $xxs;
  }
  &-xs {
    margin-top: $xs;
    margin-bottom: $xs;
  }
  &-sm {
    margin-top: $sm;
    margin-bottom: $sm;
  }
  &-md {
    margin-top: $md;
    margin-bottom: $md;
  }
  &-lg {
    margin-top: $lg;
    margin-bottom: $lg;
  }

  &-xl {
    margin-top: $xl;
    margin-bottom: $xl;
  }
  &-xxl {
    margin-top: $xxl;
    margin-bottom: $xxl;
  }
  &-xxxl {
    margin-top: $xxxl;
    margin-bottom: $xxxl;
  }
  &-top {
    &--xs {
      margin-top: $xs;
      margin-bottom: inherit;
    }
    &--sm {
      margin-top: $sm;
      margin-bottom: inherit;
    }
    &--md {
      margin-top: $md;
      margin-bottom: inherit;
    }
    &--lg {
      margin-top: $lg;
      margin-bottom: inherit;
    }

    &--xl {
      margin-top: $xl;
      margin-bottom: inherit;
    }
  }
  &-btm {
    &--xs {
      margin-bottom: $xs;
      margin-top: inherit;
    }
    &--sm {
      margin-bottom: $sm;
      margin-top: inherit;
    }
    &--md {
      margin-bottom: $md;
      margin-top: inherit;
    }
    &--lg {
      margin-bottom: $lg;
      margin-top: inherit;
    }
    &--xl {
      margin-bottom: $xl;
      margin-top: inherit;
    }
  }
}

.lead {
  @extend .strongest;
}

.rotate--180 {
  transform: rotate(180deg);
}

.pad-ratio {
  &--400x271 {
    @include aspect-ratio(400, 271); //1200x813
  }
  &--48x23 {
    @include aspect-ratio(48, 23); //1200x575
  }
  &--32x41 {
    @include aspect-ratio(32, 41); //800x1025
  }
  &--25x16 {
    @include aspect-ratio(25, 16); //800x512
  }
  &--16x9 {
    @include aspect-ratio(16, 9);
  }
  &--4x3 {
    @include aspect-ratio(4, 3);
  }
  &--2x1 {
    @include aspect-ratio(2, 1);
  }
  &--1x1 {
    @include aspect-ratio(1, 1);
  }
  &--sm {
    &--1x1 {
      @media screen and (max-width: $screen-sm-max) {
        @include aspect-ratio(1, 1);
      }
    }
    &--4x3 {
      @media screen and (max-width: $screen-sm-max) {
        @include aspect-ratio(4, 3);
      }
    }
    &--32x41 {
      @media screen and (max-width: $screen-sm-max) {
        @include aspect-ratio(32, 41);
      }
    }
  }
}

.constrainer {
  &--1200 {
    max-width: 1200px;
  }
  &--1000 {
    max-width: 1000px;
  }
  &--800 {
    max-width: 800px;
  }
  &--600 {
    max-width: 600px;
  }
}

.wrapper {
  &--opacity {
    &-children {
      &--fx {
        & > * {
          transition: opacity 0.25s ease-in-out;
          opacity: 1;
        }
        &:hover {
          opacity: 1;
          & > * {
            transition: opacity 0.25s ease-in-out;
            opacity: 0.5;
            &:hover {
              transition: opacity 0.25s ease-in-out;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.border {
  &--sm {
    border: solid 0.5rem;
  }
}
