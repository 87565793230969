body {
  font-family: $font_main;
}

body {
  font-weight: 500;
  line-height: 1.45;
}

.fs {
  &--xs {
    font-size: $xs;
  }
  &--sm {
    font-size: $xsm;
  }
}

p {
  margin-bottom: $md;
  line-height: calc(1.3em + (1.5 - 1.3) * ((100vw - 21em)/(70 - 30)))!important;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4, p {
  margin: 0;
  font-weight: inherit;
  line-height: 1.5;
}

h1, .h1 {
  margin-top: 0;
  font-weight: 900;
  font-size: $xl;
  & span {
    display: block;
  }
  @media  screen and (min-width: $screen-sm) {
    font-size: $xxl;
  }
  @media screen and (min-width: $screen-md) {
    font-size: $xxxl;
  }
  & .subline {
    font-size: 0.7rem;
    margin-bottom: 0;
    @media  screen and (min-width: $screen-xsm) {
       font-size: $xsm;
    }
  }
  &.title {
    font-size: $sm;
    line-height: 1.2;
    @media  screen and (min-width: $screen-xsm) {
       font-size: $md;
    }
    @media  screen and (min-width: $screen-sm) {
      font-size: $md;
    }
    @media screen and (min-width: $screen-md) {
      font-size: $lg;
    }
  }
  .hero--headline & {
    line-height: 1;
  }
}

h2, .h2 {
  font-size: $lg;
  @media screen and (min-width: $screen-md) {
    font-size: $xl;
  }
}

h3, .h3 {
  font-size: $md;
  @media screen and (min-width: $screen-md) {
    font-size: $xl;
  }
}

h4, .h4 {
  font-size: $md;
}

h5 {
  font-size: $xsm;
   @media screen and (min-width: $screen-md) {
    font-size: $sm;
  }
}

small, .font_small {
  font-size: 0.618em;
}

blockquote {
  &.pre-dash {
    margin: 2rem auto 2rem 2rem ;
    padding-left: 1.5rem;
    position: relative;
    z-index: 1;
    &:before {
      width: 5rem;
      left: -2rem;
      z-index: -1;
      // background-color: $yellow!important;
    }
  }
}
